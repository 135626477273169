<template>
  <a-modal
    centered
    bordered
    v-model="visible"
    :width="780"
    title="技能包"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0, 'min-height': '400px' }"
    :ok-button-props="{ props: { disabled: !selectedId } }"
    @ok="handleOk"
    @cancel="selectedId = ''"
  >
    <a-card :bordered="false">
      <div style="margin-bottom:20px">
        <a-row :gutter="[24, 0]">
          <a-form-model class="ant-advanced-search-form">
            <a-col :lg="10" :md="10" :sm="24">
              <a-form-model-item label="输入搜索：">
                <a-input-search v-model="query.name" placeholder="请输入阶段名称" @search="initData" />
              </a-form-model-item>
            </a-col>
            <a-col :lg="10" :md="10" :sm="24">
              <a-form-model-item label="分类：">
                <h-cascader
                  v-model="field"
                  :options="mainFieldsOptions"
                  :fieldNames="{
                    label: 'name',
                    value: 'id',
                    children: 'children'
                  }"
                  change-on-select
                  allowClear
                  placeholder="全部分类"
                  style="width:100%"
                  @change="initData"
                />
              </a-form-model-item>
            </a-col>
          </a-form-model>
        </a-row>
      </div>
      <a-table
        rowKey="id"
        :customRow="customRow"
        :data-source="list"
        :pagination="pagination"
        :loading="listLoading"
        @change="handleTableChange"
      >
        <a-table-column key="url" title="商品图片" align="center" width="120px">
          <template slot-scope="record">
            <a-row type="flex" align="middle" justify="space-between" style="flex-wrap:nowrap;overflow:hidden;">
              <a-col flex="0 1 10px">
                <a-radio :checked="selectedId === record.id"></a-radio>
              </a-col>
              <a-col flex="0 1 80px">
                <a-avatar :size="55" shape="square" :src="record.coverUrl"></a-avatar>
              </a-col>
              <a-col flex="0 1 10px"></a-col>
            </a-row>
          </template>
        </a-table-column>
        <a-table-column key="name" title="商品名称" align="center" data-index="name" width="220px" />
        <a-table-column
          key="mainFieldName"
          title="素材分类"
          align="center"
          data-index="field.parent.name"
          width="100px"
        />
        <a-table-column key="phaseCount" title="阶段" align="center" data-index="phaseCount" width="100px" />
      </a-table>
    </a-card>
  </a-modal>
</template>

<script>
import * as skillApi from '@/api/skill'
import * as commonAPi from '@/api/common'
export default {
  data() {
    return {
      query: {
        fieldId: undefined,
        mainFieldId: undefined,
        name: undefined,
        sort: undefined
      },
      field: [],
      selectedId: '',
      listLoading: false,
      list: [],
      pagination: {},
      mainFieldsOptions: [],
      fieldsOptions: []
    }
  },
  computed: {
    visible: {
      set: function(val) {
        this.$emit('update:show', val)
      },
      get: function() {
        return this.show
      }
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  activated() {
    if (this.ready) {
      this.initData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
    commonAPi.fieldsOptions().then(res => {
      this.mainFieldsOptions = res.data.filter(v => v.level === 'MAIN')
    })
    this.initData()
  },
  methods: {
    customRow(record) {
      return {
        on: {
          click: () => {
            this.selectedId = record.id
          }
        }
      }
    },
    handleOk() {
      this.visible = false
      const { selectedId } = this
      this.$emit('confirm', selectedId)
    },
    initData() {
      this.pagination = this.initPagination()

      this.getData()
    },
    getData() {
      this.listLoading = true
      const {
        pagination: { current: pageIndex, pageSize },
        query,
        field,
        $route
      } = this
      const { id: student } = $route.params
      query.mainFieldId = field[0]
      query.fieldId = field[1]
      skillApi
        .list({
          status: 'PUBLISHED',
          pageIndex,
          pageSize,
          ...query,
          student,
          isAddPlan: true,
          isGoodsHasPublished: true
        })
        .then(res => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 4,
        showTotal: total => {
          const h = this.$createElement
          const {
            pagination: { pageSize }
          } = this
          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'error-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 4)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'error-color' }, total),
            h('span', ' 条数据')
          ])
        },
        showSizeChanger: false,
        total: 0,
        pageSizeOptions: ['4']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
