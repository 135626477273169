<template>
  <a-form-model ref="form" :model="form" v-bind="$formItemLayout4DetailSm">
    <a-form-model-item
      label="封面："
      prop="coverUrl"
      :rules="{
        required: true,
        message: '请上传封面',
        trigger: 'blur'
      }"
    >
      <image-upload :url="form.coverUrl" @success="handleCoverUrl" @remove="form.coverUrl = ''"></image-upload>
    </a-form-model-item>
    <a-form-model-item
      label="名称："
      prop="goodsName"
      :rules="{
        required: true,
        message: '请输入技能包名称',
        trigger: 'blur'
      }"
    >
      <div slot="extra">默认为技能包名称，可修改</div>
      <a-input v-model.trim="form.goodsName" :maxLength="25" />
    </a-form-model-item>
    <a-form-model-item label="所属领域" prop="classification.id">
      <h-select disabled v-model="form.classification.name"> </h-select>
    </a-form-model-item>
    <a-form-model-item
      label="原始售价："
      prop="originPrice"
      :rules="{
        required: true,
        message: '原始售价不能为空',
        trigger: 'blur'
      }"
    >
      <a-input-number
        v-model.trim="form.originPrice"
        :min="0"
        :max="999999.99"
        :precision="1"
        :formatter="value => `￥${value}`"
        :parser="value => value.replace('￥', '')"
      />
    </a-form-model-item>
    <!--    不自动计算折扣，暂时注释-->
    <!--    <a-form-model-item-->
    <!--      label="折扣："-->
    <!--      prop="discount"-->
    <!--      :rules="{-->
    <!--        required: true,-->
    <!--        message: '折扣不能为空',-->
    <!--        trigger: 'blur'-->
    <!--      }"-->
    <!--    >-->
    <!--      <a-input-number-->
    <!--        v-model.trim="form.discount"-->
    <!--        :min="0"-->
    <!--        :max="100"-->
    <!--        :formatter="value => `${value}%`"-->
    <!--        :parser="value => value.replace('%', '')"-->
    <!--      />-->
    <!--    </a-form-model-item>-->
    <a-form-model-item
      label="实际售价："
      prop="sellPrice"
      :rules="{
        required: true,
        message: '实际售价不能为空',
        trigger: 'blur'
      }"
    >
      <a-input-number
        v-model.trim="form.sellPrice"
        :min="0"
        :max="999999.99"
        :precision="1"
        :formatter="value => `￥${value}`"
        :parser="value => value.replace('￥', '')"
      />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { cloneDeep } from 'lodash'
import { ImageUpload } from '@/components/_heath'
export default {
  components: {
    ImageUpload
  },
  data() {
    return {
      form: {
        goodsId: '',
        skillPackId: '',
        coverUrl: '',
        goodsName: '',
        classification: {},
        // discount: 100,
        originPrice: '',
        sellPrice: ''
      }
    }
  },
  computed: {
    // priceRange() {
    //   const {
    //     form: { originPrice = 0, discount = 0 }
    //   } = this
    //   return {
    //     originPrice,
    //     discount
    //   }
    // }
  },
  watch: {
    // priceRange(val) {
    //   this.form.sellPrice = (val.originPrice * val.discount) / 100
    // }
  },
  methods: {
    initData(form) {
      this.form = Object.assign({}, this.form, form)
    },
    validate() {
      const {
        $refs: { form }
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    formatForm() {
      try {
        const ret = cloneDeep(this.form)
        return { ...ret }
      } catch {}
    },
    handleCoverUrl(url) {
      this.form.coverUrl = url
      this.$refs.form.clearValidate('coverUrl')
    }
  }
}
</script>

<style lang="less" scoped>
.label-title {
  position: absolute;
  top: 10px;
  left: -150px;
  width: 150px;
  height: 50px;
  background: #9ea7b4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  &::after {
    position: absolute;
    right: -50px;
    top: 0;
    content: '';
    width: 0px;
    height: 0px;
    border-top: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 25px solid #9ea7b4;
  }
}
</style>
