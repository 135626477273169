<template>
  <div>
    <a-card>
      <a-form-model style="margin-bottom:20px" ref="form" :model="{}" v-bind="$formItemLayout4DetailSm">
        <a-form-model-item label="技能包选择" :rules="{ required: true }">
          <a-button
            type="primary"
            shape="round"
            v-show="status !== -1"
            @click="showSkillModel = true"
            style="width:100px"
          >
            {{ ['选择', '修改'][status] }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <template v-if="status == 0">
        <a-col style="margin:90px auto 180px auto" type="flex" align="middle">
          <a-row>
            <a-avatar :size="110" shape="square" src="record.url"></a-avatar>
          </a-row>
          <a-row style="margin-top:20px">
            <span class="add-tips">您还没添加任何技能包</span>
          </a-row>
        </a-col>
      </template>
      <template v-else-if="status == 1">
        <a-row>
          <a-col :sm="18" :xs="{ span: 22, offset: 1 }">
            <a-card>
              <a-row type="flex" align="middle">
                <a-col style="margin-right:20px">
                  <a-avatar :size="70" shape="square" :src="query.coverUrl"></a-avatar>
                </a-col>
                <a-col flex="1" class="skill-detail">
                  <a-row>{{ query.name }}</a-row>
                  <a-row type="flex" align="middle" :gutter="12">
                    <a-col>分类：{{ query.field.parent.name }} > {{ query.field.name }}</a-col>
                    <a-col>阶段数：{{ query.phaseCount }} </a-col>
                    <a-col>创建人：{{ query.creator }}</a-col>
                  </a-row>
                  <a-row>目标：{{ query.goal }}</a-row>
                </a-col>
              </a-row>
            </a-card>
            <a-card :bordered="false">
              <a-col v-for="(phaseInfo, phaseIndex) in query.phaseList" :key="phaseInfo.id" style="margin-bottom:18px">
                <a-row type="flex" align="middle" style="font-size:16px">
                  <a-icon style="margin-right:6px" type="tag" theme="filled" :rotate="135" />
                  <a-col>第{{ phaseIndex + 1 }}阶段</a-col>
                </a-row>
                <a-row type="flex" align="middle" style="margin-top:8px;">
                  <a-col style="font-size:12px;padding-left:20px">
                    阶段目标：
                  </a-col>
                  <a-col style="font-size:14px">{{ phaseInfo.goal }}</a-col>
                </a-row>
              </a-col>
            </a-card>
          </a-col>
        </a-row>
      </template>
      <a-card :bordered="false">
        <a-row type="flex" align="middle" justify="center">
          <a-col>
            <a-button :disabled="status !== 1" type="primary" shape="round" style="width:200px" @click="onSubmit">
              下一步，编辑基本信息
            </a-button>
          </a-col>
        </a-row>
      </a-card>
    </a-card>
    <SkillModel :show.sync="showSkillModel" @confirm="onConfirm" />
  </div>
</template>

<script>
import SkillModel from './SkillModel'
import * as skillApi from '@/api/skill'
export default {
  components: {
    SkillModel
  },
  data() {
    return {
      status: -1,
      disabled: true,
      showSkillModel: false,
      query: {
        id: '',
        name: '',
        coverUrl: '',
        field: {},
        creator: '',
        phaseCount: '',
        goal: '',
        phaseList: []
      }
    }
  },
  methods: {
    initData(id) {
      if (id) {
        skillApi.detail(id).then(res => {
          const { id, name, coverUrl, field, creator, phaseCount, goal, phaseList } = res.data
          this.query = { id, name, coverUrl, field, creator, phaseCount, goal, phaseList }
          this.status = 1
        })
      } else {
        this.status = 0
      }
    },
    onSubmit() {
      const {
        query: { id: skillPackId, field: classification, name: goodsName, coverUrl }
      } = this
      this.$emit('change', { skillPackId, goodsName, classification, coverUrl })
    },
    onConfirm(id) {
      this.initData(id)
    }
  }
}
</script>

<style lang="less" scoped>
.skill-detail {
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.add-tips {
  font-size: 16px;
  font-weight: 600;
  color: #666;
}
</style>
