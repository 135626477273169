<template>
  <a-card>
    <div slot="title">基本信息</div>
    <basis-form ref="basis" />
    <a-card :bordered="false">
      <a-row type="flex" align="middle" :gutter="20" justify="center">
        <a-col>
          <a-button style="width:160px" shape="round" @click="onBack">
            上一步，选择技能包
          </a-button>
        </a-col>
        <a-col>
          <a-button :loading="loading" type="primary" shape="round" style="width:160px" @click="onSubmit">
            保存
          </a-button>
        </a-col>
      </a-row>
    </a-card>
  </a-card>
</template>

<script>
import BasisForm from './BasisForm'
import * as goodsskillApi from '@/api/goodsskill'
import events from '@/components/MultiTab/events'
export default {
  name: 'GoodsManagementSkillEdit',
  data() {
    return {
      loading: false
    }
  },
  components: {
    BasisForm
  },
  methods: {
    initData(form) {
      const { goodsId, skillPackId, goodsName: _goodsName, classification: _classification, coverUrl: _coverUrl } = form
      if (goodsId) {
        goodsskillApi.detail(goodsId).then(res => {
          const { data, code } = res
          if (code === 0) {
            const { goodsName, classification, coverUrl, discount, originPrice, sellPrice } = data
            let skillInfo = {}
            // 优先使用更换的技能包
            if (_goodsName || _classification || _coverUrl) {
              skillInfo = { goodsName: _goodsName, classification: _classification, coverUrl: _coverUrl }
            } else {
              skillInfo = { goodsName, classification, coverUrl }
            }
            this.$refs.basis.initData({
              goodsId,
              discount,
              originPrice,
              sellPrice,
              skillPackId,
              ...skillInfo
            })
          }
        })
      } else {
        this.$refs.basis.initData({ ...form })
      }
    },
    onBack() {
      this.$emit('revert')
    },
    onSubmit() {
      const {
        $refs: { basis },
        $notification
      } = this
      basis.validate().then(valid => {
        if (valid) {
          this.loading = true
          try {
            const { formatForm } = basis
            const form = formatForm()
            const {
              classification: { id }
            } = form
            delete form.classification
            if (form.goodsId) {
              goodsskillApi
                .update({ classification: id, ...form })
                .then(res => {
                  const { code, msg } = res
                  if (code === 0) {
                    $notification['success']({ message: '操作成功' })
                    this.close()
                  } else {
                    $notification['error']({ message: `${msg}` })
                  }
                })
                .finally(() => (this.loading = false))
            } else {
              goodsskillApi
                .create({ classification: id, ...form })
                .then(res => {
                  const { code, msg } = res
                  if (code === 0) {
                    $notification['success']({ message: '操作成功' })
                    this.close()
                  } else {
                    $notification['error']({ message: `${msg}` })
                  }
                })
                .finally(() => (this.loading = false))
            }
          } catch {
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    close() {
      events.$emit('close')
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-input-number {
  width: 100%;
}
</style>
