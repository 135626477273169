var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-model',_vm._b({ref:"form",attrs:{"model":_vm.form}},'a-form-model',_vm.$formItemLayout4DetailSm,false),[_c('a-form-model-item',{attrs:{"label":"封面：","prop":"coverUrl","rules":{
      required: true,
      message: '请上传封面',
      trigger: 'blur'
    }}},[_c('image-upload',{attrs:{"url":_vm.form.coverUrl},on:{"success":_vm.handleCoverUrl,"remove":function($event){_vm.form.coverUrl = ''}}})],1),_c('a-form-model-item',{attrs:{"label":"名称：","prop":"goodsName","rules":{
      required: true,
      message: '请输入技能包名称',
      trigger: 'blur'
    }}},[_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_vm._v("默认为技能包名称，可修改")]),_c('a-input',{attrs:{"maxLength":25},model:{value:(_vm.form.goodsName),callback:function ($$v) {_vm.$set(_vm.form, "goodsName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.goodsName"}})],1),_c('a-form-model-item',{attrs:{"label":"所属领域","prop":"classification.id"}},[_c('h-select',{attrs:{"disabled":""},model:{value:(_vm.form.classification.name),callback:function ($$v) {_vm.$set(_vm.form.classification, "name", $$v)},expression:"form.classification.name"}})],1),_c('a-form-model-item',{attrs:{"label":"原始售价：","prop":"originPrice","rules":{
      required: true,
      message: '原始售价不能为空',
      trigger: 'blur'
    }}},[_c('a-input-number',{attrs:{"min":0,"max":999999.99,"precision":1,"formatter":value => `￥${value}`,"parser":value => value.replace('￥', '')},model:{value:(_vm.form.originPrice),callback:function ($$v) {_vm.$set(_vm.form, "originPrice", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.originPrice"}})],1),_c('a-form-model-item',{attrs:{"label":"实际售价：","prop":"sellPrice","rules":{
      required: true,
      message: '实际售价不能为空',
      trigger: 'blur'
    }}},[_c('a-input-number',{attrs:{"min":0,"max":999999.99,"precision":1,"formatter":value => `￥${value}`,"parser":value => value.replace('￥', '')},model:{value:(_vm.form.sellPrice),callback:function ($$v) {_vm.$set(_vm.form, "sellPrice", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.sellPrice"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }